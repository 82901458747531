import React, { useState, useEffect } from "react"
import { Router } from "@reach/router"
import GiftCardCertificate from "../../components/pitstop/GiftCardCertificate"
import LoadingComponent from "../../components/LoadingComponent"

const checkURLHash = async (
  certificate_id,
  setGiftDetails,
  setPageNotFound
) => {
  try {
    fetch(`${process.env.GATSBY_PITSTOP_BACKEND}/delivery/${certificate_id}/`, {
      method: "GET",
      mode: "cors",
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        }
        throw Error("Invalid URL")
      })
      .then(result => {
        const giftDetails = result
        setGiftDetails(giftDetails)
      })
      .catch(error => {
        setPageNotFound(true)
      })
  } catch (error) {
    setPageNotFound(true)
  }
}

function GiftCertificatePageWrapper(props) {
  return (
    <article className="gift-certificate-page">
      {!props.giftDetails ? (
        <>
          {!props.pageNotFound ? (
            <LoadingComponent />
          ) : (
            <p>{`Error loading gift certificate`}</p>
          )}{" "}
        </>
      ) : (
        <>
          <GiftCardCertificate
            giftDetails={props.giftDetails}
            data={props.data}
          />
        </>
      )}
    </article>
  )
}

export default props => {
  const [giftDetails, setGiftDetails] = useState(null)
  const [pageNotFound, setPageNotFound] = useState(false)

  useEffect(() => {
    if (props.location.search) {
      const urlHash = new URLSearchParams(props.location.search)
      const certificate_id = urlHash.get("i")
      console.log(certificate_id)
      checkURLHash(certificate_id, setGiftDetails, setPageNotFound)
    }
  }, [])

  return (
    <GiftCertificatePageWrapper
      giftDetails={giftDetails}
      pageNotFound={pageNotFound}
      data={props.data}
    />
  )
}
